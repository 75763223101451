<template>
  <h1>Novo usuário</h1>
  <form-user @save="saveUser" />
</template>

<script>
import FormUser from './Form.vue'
import userApi from '@/api/users';

export default ({
  components: { FormUser },
  methods: {
    async saveUser(user) {
      const payload = {
        name       : user.name.value,
        email      : user.email.value,
        newPassword: user.newPassword.value,
        status     : user.status.value,
        admin      : user.admin,
        permissions: user.permissions
      }

      try {
        await userApi.saveUser(payload);
        this.$toast.add({severity:'success', summary: 'Dados salvos.', life: 3000});

        this.$router.push('/config/users');
      } catch (error) {
        switch (error.response.data.errorCode) {
          case 1:
            this.$toast.add({severity:'error', summary: 'Já existe um usuário com este e-mail.'});
            break;

          default:
            this.$toast.add({severity:'error', summary: 'Não foi possível salvar os dados.'});
        }
      }
    }
  }
})
</script>
